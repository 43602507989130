import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Photography from './Pages/Photography';
import 'tailwindcss/tailwind.css';
import Home from './Pages/Home';
class App extends Component {
  render() {
    return (
    
        <Router>
         <Routes>
         <Route path="/" element={<Home/>} />
          <Route path="/photography" element={<Photography/>} />
        </Routes>
      </Router>
      
    );
  }
}

export default App;