let resumeData = {
  imagebaseurl: "https://github.com/Omar4GH",
  name: "Omar Ghamrawi",
  role: "Software Engineer",
  linkedinId: "https://www.linkedin.com/in/omar-ghamrawi-31b29b217/",
  skypeid: "Your skypeid",
  roleDescription:
    "I'm a 23 Years old Lebanese Software Engineer, currently in Lebanon. Always had the passion for Programming, Games and Websites Development, and learning any new things i can learn. With hobbies like Photography, Parkour and Freerunning, Video Editing and Gaming... ",
  socialLinks: [
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/omar-ghamrawi-31b29b217/",
      className: "fa fa-linkedin",
    },
    {
      name: "github",
      url: "https://github.com/Omar4GH",
      className: "fa fa-github",
    },
    {
      name: "Youtube",
      url: "https://www.youtube.com/channel/UC4dExORzyUiPRtXmn8eWZiQ",
      className: "fa fa-youtube",
    },
    {
      name: "Youtube",
      url: "https://500px.com/p/Omar-GH?view=photos",
      className: "fa fa-picture-o",
    },
    {
      name: "Instagram",
      url: "https://www.instagram.com/omarghamrawi/?hl=en",
      className: "fa fa-instagram",
    },
  ],
  aboutme:
    "I am a Software Engineer, a Graduate from Boğaziçi University in Istanbul, Turkey."+
    "I've had work experience and built full projects with ReactJS, ReactTS, ReactNative, NodeJs, MYSQL, MongoDB, Yii Framework, API Requests, Docker... Overall experience in Frontend and Backend Development, with AWS and GoogleCloud Deployment."+
    "In addition to working with big teams, respecting the team work environment in Github, with managing Issues, branches, pull requests... Experienced in Android Studio, Unity Engine, C, C#, Java, HTML, Javascript, CSS, PHP...",
  address: "Istanbul, Turkey",
  website: "",

  education: [
    {
      UniversityName: "Boğaziçi University",
      specialization: "Masters Software Engineering | 3.3 GPA",
      MonthOfPassing: "October 2022",
      YearOfPassing: " - 2024",
      Achievements: "Istanbul,Turkey",
    },
    {
      UniversityName: "Uskudar University",
      specialization: "Software Engineering | 3.2 GPA",
      MonthOfPassing: "2018",
      YearOfPassing: "- 2022",
      Achievements: "Istanbul, Turkey",
    },
    {
      UniversityName: "Azm Institute",
      specialization: "Information Technology | 2.93 GPA",
      MonthOfPassing: "2016",
      YearOfPassing: "- 2018",
      Achievements: "Tripoli, Lebanon",
    },
  ],
  certificates: [ {
    UniversityName:
      "Professional Diploma Program In Information Technology, Pearson Assured",
    specialization: "At Azm Institute, Pearson Assured - GPA 2.93",
    MonthOfPassing: "July",
    YearOfPassing: "2018",
    Achievements: "Tripoli, Lebanon",
  }, {
    UniversityName:
      "Software Engineer - Bachelor of Science",
    specialization: "Uskudar University - Bachelor's Degree in Software Engineering GPA3.2",
    MonthOfPassing: "August",
    YearOfPassing: "2022",
    Achievements: "Istanbul, Turkey",
  },
    {
      UniversityName: "Cisco - IT Essentials",
      specialization:
        "IT Essentials at Cisco Networking academy - Score : 95.9%",
      MonthOfPassing: "September",
      YearOfPassing: "2014",
      Achievements: "Tripoli, Lebanon",
    },
    {
      UniversityName: "Cisco - CCNA Routing and Switching",
      specialization: "Cisco Certified Network Associate , Course Completion",
      MonthOfPassing: "April",
      YearOfPassing: "2018",
      Achievements: "Tripoli, Lebanon",
    },
    {
      UniversityName: "LAU - Computing Summer Camp",
      specialization: "At Lebanese American University - 3rd Place",
      MonthOfPassing: "July",
      YearOfPassing: "2016",
      Achievements: "Tripoli, Lebanon",
    },
    {
      UniversityName: "Unity - How To Make A Video Game Level 1",
      specialization:
        "At Al Amine International Academy For Training And Development",
      MonthOfPassing: "February",
      YearOfPassing: "2017",
      Achievements: "Tripoli, Lebanon",
    },
    {
      UniversityName: "Unity - Advanced Level 2",
      specialization:
        "At Al Amine International Academy For Training And Development",
      MonthOfPassing: "May",
      YearOfPassing: "2017",
      Achievements: "Tripoli, Lebanon",
    },
    {
      UniversityName: "Unity - Networking and VR Level 3",
      specialization:
        "At Al Amine International Academy For Training And Development",
      MonthOfPassing: "July",
      YearOfPassing: "2017",
      Achievements: "Tripoli, Lebanon",
    },
   
    {
      UniversityName: "Science Competition - Certificate of Participation",
      specialization: "Science Competition in Lebanon",
      MonthOfPassing: "",
      YearOfPassing: "2017",
      Achievements: "Tripoli, Lebanon",
    },
  ],
  work: [
    {
      CompanyName: "DevTech",
      specialization: "Intern - Frontend, Backend ",
      MonthOfLeaving: "Jul 2022",
      YearOfLeaving: " - Sept 2023",
      Achievements:
        "Regular ReactJs projects | CSS design/Tailwind | Using React Libraries | Axios API calls, Databses, Crypto Wallet lntergration/Payment Processor Integration | Changes, Fixes, and Tasks on an Ecommerce Yii2 Website |  Working respecting Github Branches, Pull Requests, Reviews ... Used Docker Images, MongoDB, Xampp, PhpMyadmin, Postman ...",
    },
    {
      CompanyName: "Freelance Work",
      specialization:
        "Video/Photo Editing (Adobe premierPro,Lightroom,Photoshop,AfterEffects)",
      MonthOfLeaving: "",
      YearOfLeaving: "",
      Achievements: "Online",
    },
  ],
  skillsDescription: "",
  skills: [
    {
      skillname: "ReactJS",
    },
    {
      skillname: "ReactTS",
    },
    {
      skillname: "ReactNative",
    },
    {
      skillname: "NodeJS",
    },
    {
      skillname: "MongoDB",
    },
    {
      skillname: "MySQL",
    },
    {
      skillname: "CSS",
    },
    {
      skillname: "AWS",
    },
    {
      skillname: "Adobe Softwares",
    },
    {
      skillname: "HTML5",
    },
    {
      skillname: "Java",
    },
    {
      skillname: "Javascript",
    },
    {
      skillname: "PHP",
    },
    {
      skillname: "C",
    },
    {
      skillname: "Unity Engine",
    },
  ],
  portfolio: [
    {
      name: "Vivid",
      subtitle: "Latest Youtube Video",
      websiteUrl: "https://www.youtube.com/channel/UC4dExORzyUiPRtXmn8eWZiQ",
      description: "My Youtube Channel where i share edited videos and experiences.",
      imgurl: "images/portfolio/phone.jpg",
      url: "https://www.youtube.com/embed/CpjIoBKG5J8?si=6zyQR8AUi1_t3eoS",
    },
    {
      name: "GeoMemoirs",
      subtitle: "Web App",
      websiteUrl:"https://geo-memoirs.netlify.app/",
      description: "GeoMemoirs is a React App build by Omar Ghamrawi , a project for SWE-573 , MSc.Software Engineering Boğaziçi University 2023, with NodeJs as a backend, using MYSQL for database. Backend deployed on AWS as a docker image, along with the database. And the frontend deployed on a free web hosting service",
      imgurl: "images/DSC_0296.jpg",
      url: "https://www.youtube.com/embed/bcEXsGODIY0?si=ddXZYxrAJ9QYAtv0",
    },
    {
      name: "LivingStories Mobile App",
      subtitle: "Mobile App",
      websiteUrl:"https://www.youtube.com/watch?v=fRT3WcLz9Qk",
      description: "A Mobile app developed using ReactNative",
      imgurl: "images/portfolio/livingstories.png",
      url: "https://www.youtube.com/embed/fRT3WcLz9Qk?si=HWbH9R2vT9w351hx",
    },
    {
      name: "LucidMonkee",
      subtitle: "FPS Game",
      websiteUrl:"https://www.youtube.com/watch?v=qND2xdlTUSM&ab_channel=OmarGhamrawi",
      description: "A 3D First Person Shooter Game created in Unity Engine, still under developement by Omar Ghamrawi, using free assets from the internet, and some assets and animations created in Blender.",
      imgurl: "images/portfolio/project2.png",
      url: "https://www.youtube.com/embed/qND2xdlTUSM?si=Bb4_IQvqs27JqKkc",
    },
    {
      name: "SmartJobs",
      subtitle: "Web App",
      websiteUrl:"https://smart-jobs.netlify.app/",
      description: "A Job Posting Web App developed with React JS as Frontend, and NodeJS as"
      +" Backend using MongoDB as a Database, with a Mailing System for Weekly"
     +"Digest, Recommended Jobs and Saved Jobs Reminders. Deployed on AWS. ",
      imgurl: "images/portfolio/smartjobs.png",
      url: "https://www.youtube.com/embed/1AH9fTT5n44?si=pk6BfPFZVRudkVKF",
    },
    {
      name: "LivingStories Web Frontend",
      subtitle: "Web App",
      websiteUrl:"https://www.youtube.com/watch?v=n-zY_CPTbWg",
      description: "Frontend Developed by Omar Ghamrawi, on a big project with other individuals.",
      imgurl: "images/portfolio/livingstories.png",
      url: "https://www.youtube.com/embed/n-zY_CPTbWg?si=fs4FaYAxiSCgg9s4",
    },
  ],
  testimonials: [
    {
      description: "This is a sample testimonial",
      name: "Some technical guy",
    },
    {
      description: "This is a sample testimonial",
      name: "Some technical guy",
    },
  ],
};

export default resumeData;
