import React, { Component } from "react";
import { Link } from "react-router-dom";
import resume from "./files/resume.pdf";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
//import ForWebLowerQ from "./media/ForWebLowerQ.mp4";
//import ForMobileVideo from "./media/ForMobileVideo.mp4"; 

export default class Header extends Component {


  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= 767,
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= 767 });
  };

  render() {

    const vid1= 'https://www.youtube.com/embed/CpjIoBKG5J8?si=dfjOegznpRQ2d8bV';

    const { isMobile } = this.state;

    const downloadPDF = () => {
      const link = document.createElement('a');
      link.href = resume;
      link.download = 'Resume.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    let resumeData = this.props.resumeData;
    return (
      <React.Fragment>
        <header id="home">
        {/* isMobile ? ( // Conditional rendering based on screen size
            <video src={ForMobileVideo} autoPlay loop muted />
          ) : (
            <video src={vid1} autoPlay loop muted />
          )*/}
    
          <nav id="nav-wrap">
            <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
              Show navigation
            </a>
            <a className="mobile-btn" href="#" title="Hide navigation">
              Hide navigation
            </a>
            <ul id="nav" className="nav">
              <li className="current">
                <a className="smoothscroll" href="#home">
                  Home
                </a>
              </li>

              <li>
                <a className="smoothscroll" href="#about">
                  About
                </a>
              </li>
              <li>
                <a className="smoothscroll" href="#resume">
                  Resume
                </a>
              </li>
              <li>
                <a className="smoothscroll" href="#portfolio">
                  Works
                </a>
              </li>
              {/*  <li><a className="smoothscroll" href="#testimonials">Testimonials</a></li> */}
              <li className="">
                <Link to="/photography"> Gallery|Beta</Link>
              </li>
              <li>
                <a className="smoothscroll" href="#contact">
                  Contact
                </a>
              </li>
              <li>
                <a className="smoothscroll" href="#" onClick={downloadPDF}>
                  <CloudDownloadIcon fontSize="large" />CV
                </a>
              </li>
            </ul>
          </nav>

          <div className="row banner">
            <div className="banner-text">
              <h1 className="responsive-headline">I am Omar Ghamrawi.</h1>
              <h3 style={{ color: "#fff", fontFamily: "sans-serif " }}>
                {resumeData.roleDescription}
              </h3>

              <hr />
              <ul className="social">
                {resumeData.socialLinks &&
                  resumeData.socialLinks.map((item) => {
                    return (
                      <li key={item.name}>
                        <a href={item.url} target="_blank">
                          <i className={item.className} />
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>

          <p className="scrolldown">
            <a className="smoothscroll" href="#about">
              <i className="icon-down-circle" />
            </a>
          </p>
        </header>
      </React.Fragment>
    );
  }
}
