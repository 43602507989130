import Gallery from "react-photo-gallery";
import React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import itemData from "./itemData.js";
import { Link } from 'react-router-dom';

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: image,
    srcSet: `${image} ${size * cols}w`,
  };
}

const Photography = () => {
  return (
    <div><div>
               <nav id="nav-wrap">
            <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
          <a className="mobile-btn" href="#" title="Hide navigation">Hide navigation</a>
            <ul id="nav" className="nav">
               <li className="smoothscroll"><Link to="/">Home</Link></li>
               
               <li className="smoothscroll"><Link to="/">About</Link></li>
               <li className="smoothscroll"><Link to="/">Resume</Link></li>
               <li className="smoothscroll"><Link to="/">Works</Link></li>
              {/*  <li><a className="smoothscroll" href="#testimonials">Testimonials</a></li> */}
                <li className="current"><Link to="/photography">Gallery|Beta</Link></li>
                <li className="smoothscroll"><Link to="/">Contact</Link></li>
              
            </ul>
         </nav></div>
         <br/><br/><br/>
         <div className="row banner">
            <div className="banner-text">
            <h3 style={{color:'#fff', fontFamily:'sans-serif '}}>This is my Photo Gallery, <a href="https://500px.com/p/Omar-GH?view=photos" target="_blank">Check my 500px Profile</a> 
               </h3><br/></div></div>
      <ImageList
        sx={{ width: "100%", height: "100%" }}
        variant="quilted"
        cols={4}
        rowHeight={121}
      >
        {itemData.map((item) => (
          <ImageListItem
            key={item.img}
            cols={item.cols || 1}
            rows={item.rows || 1}
          >
            <img
              {...srcset(item.img, 121, item.rows, item.cols)}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
};

export default Photography;
