import React, { Component } from 'react';
export default class ContactUs extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="contact">
          <div className="row section-head">
            <div className="ten columns">
              <p className="lead">
              Feel free to contact me through Email and LinkedIn
              </p>
            </div>
          </div>
          <div className="row">
            <aside className="eigth columns footer-widgets">
              <div className="widget">
              <h4>Email :
                  omarghamrawi18@gmail.com
                </h4>
                <h4>Linked in :<a href="https://www.linkedin.com/in/omar-ghamrawi-31b29b217/"> Omar Ghamrawi</a> 
                </h4>
              </div>
            </aside>
          </div>
        </section>
        );
  }
}