import React, { Component } from "react";
import Tooltip from "@mui/material/Tooltip";
import image1 from "./IMG_0838.jpg";
import image2 from "./img2.png";
import image3 from "./livingstories.png";
import image4 from "./monkee.PNG";
import image5 from "./smartjobs.png";
import image6 from "./livingstories.png";
export default class Porfolio extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hoveredItem: null,
    };
  }

  handleItemHover = (index) => {
    this.setState({ hoveredItem: index });
  };

  render() {
    let resumeData = this.props.resumeData;
    const { hoveredItem } = this.state;
    
    const backgroundStyles = {
      backgroundImage: `url(${
        hoveredItem === 0 ? image1 : hoveredItem === 1 ? image2 : hoveredItem === 2 ? image3 : hoveredItem === 3 ? image4 : hoveredItem === 4 ? image5 : hoveredItem === 5 ? image6 : ''
      })`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    };

    return (
      <div className="bg-gray-50 pt-32 pb-32" style={backgroundStyles}>
        <h2 className="text-3xl font-semibold text-center mb-11">Work</h2>

        <div className="flex flex-wrap justify-center">
          {resumeData.portfolio &&
            resumeData.portfolio.map((item, index) => (
              <Tooltip
                title={<span style={{ fontSize: '16px' }}>Click for more</span>}
                followCursor
              >
                  <a
                  onMouseEnter={() => this.handleItemHover(index)}
                  onMouseLeave={() => this.handleItemHover(null)}
                  key={index}
                  href={item.websiteUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-white rounded-lg mx-10 mb-12 shadow-lg hover:scale-105 transform transition-transform duration-300"
                  style={{ width: "400px" }}
                >
                  <div className="relative aspect-ratio-16/9 mb-4">
                    <iframe
                      width="100%"
                      height="255"
                      title={`Video ${index}`}
                      src={item.url}
                      frameBorder="0"
                      allowFullScreen
                    />
                  </div>

                  <div className="p-8">
                    <h3 className="text-4xl font-bold text-gray-900 mb-2">
                      {item.name}
                    </h3>
                    <p className="text-2xl text-gray-700">{item.subtitle}</p>
                    <p className="text-2xl text-gray-500">{item.description}</p>
                  </div>
                </a>
              </Tooltip>
            ))}
        </div>
      </div>
    );
  }
}

{
  /* <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {resumeData.portfolio &&
        resumeData.portfolio.map((item, index) => (
          <div key={index} className="bg-white rounded-lg shadow-lg">
           
            <div className="relative aspect-ratio-16/9 mb-4">
              <iframe
                width="560"
                height="315"
                title={`Video ${index}`}
                src={item.url}
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
           
            <h3 className="text-lg font-semibold mb-2">{item.name}</h3>
           
            <p className="text-gray-700">{item.description}</p>
          </div>
        ))}
    </div>
    */
}
