const itemData = [
    {
      img: 'https://drscdn.500px.org/photo/1073220450/m%3D900/v2?sig=1bd5d14d41882c30088f981c289a5db3cb277f1c6c08de4f7b249b52f0214b0a',
      title: 'Omar Ghamrawi',
      rows: 5,
      cols: 1,
    },
    {
      img: 'https://drscdn.500px.org/photo/1072164762/m%3D900/v2?sig=5b7770a229c2f6b316a7a2d648f5a3eff063722b0bf2c81cc996ff42b0e90af7',
      title: 'Omar Ghamrawi',
      rows: 5,
      cols: 1,
    },
    {
      img: 'https://drscdn.500px.org/photo/1073220451/m%3D900/v2?sig=e4d049adff173d997cf6f7e00e529ae26565b1c91a71388711d2b6f71a017a67',
      title: 'Omar Ghamrawi',
      rows: 5,
      cols: 1,
    },
    {
      img: '    https://drscdn.500px.org/photo/1073220452/m%3D900/v2?sig=70fa319b6e3231a19f4b0872bb2f396f8cb55c8c682af107dc35187655e5ee8d',
      title: 'Omar Ghamrawi',
      rows: 5,
      cols: 1,
    },
    {
      img: 'https://drscdn.500px.org/photo/1064063439/m%3D900/v2?sig=52cbff4f6dcd5c35845735e2cb31b351926056836afa20fed37a19179be52c3a',
      title: 'Omar Ghamrawi',
      rows: 5,
      cols: 2,
    },
    {
      img: 'https://drscdn.500px.org/photo/1064063482/m%3D900/v2?sig=7b462d26f54b11c0f79b9b10a2ffbcdd303280ae85967c62747c75c0a4b886c1',
      title: 'Omar Ghamrawi',
      rows: 5,
      cols: 1,
    },
    {
      img: './PortfolioGallery/ph (15).jpg',
      title: 'Omar Ghamrawi',
      rows: 5,
      cols: 1,
    },
    {
      img: './PortfolioGallery/ph (4).jpg',
      title: 'Omar Ghamrawi',
      rows: 4,
      cols: 2,
    },
    {
      img: 'https://drscdn.500px.org/photo/1055802928/m%3D900/v2?sig=e4a5f511fa9e9a3264cd115349fca093d66e133230ddfda6e8bad95dc63ca2b5',
      title: 'Omar Ghamrawi',
      rows: 5,
      cols: 2,
    },
    {
      img: 'https://drscdn.500px.org/photo/1057275246/m%3D900/v2?sig=c0bfa730fbc53b177bee4814125e269f133d78549fa12cbb273e2cb913a54baf',
      title: 'Omar Ghamrawi',
      rows: 5,
      cols: 1,
    },
    {
      img: 'https://drscdn.500px.org/photo/1052218368/m%3D900/v2?sig=666f168eea92672c1b6496406e63d4996ce18781c2a734798b048c59f016a15a',
      title: 'Omar Ghamrawi',
      rows: 5,
      cols: 1,
    },
    {
      img: 'https://drscdn.500px.org/photo/1064063420/m%3D900/v2?sig=ea479aac77198d9c895e99d8e202486a7a1cdcadeec59a280fe6fe4ea6eb365f',
      title: 'ship climb',
      rows: 4,
      cols: 2,
    },
    {
      img: './PortfolioGallery/ph (19).jpg',
      title: 'Omar Ghamrawi',
      rows: 4,
      cols: 2,
    },
    {
      img: 'https://drscdn.500px.org/photo/1064063447/m%3D900/v2?sig=8ffacc247fc0138277e0bc9a847a11e73d8a8caf1f836aaa40828fac34448a91',
      title: 'Omar Ghamrawi',
      rows: 3,
      cols: 2,
    },
    {
      img: './PortfolioGallery/ph (50).jpg',
      title: 'Omar Ghamrawi',
      rows: 3,
      cols: 2,
    },
    {
      img: './PortfolioGallery/ph (46).jpg',
      title: 'maarad',
      rows: 4,
      cols: 2,
    },   
      {
      img: './PortfolioGallery/ph (8).jpg',
      title: 'Omar Ghamrawi',
      rows: 4,
      cols: 2,
    },   
      {
      img: './PortfolioGallery/ph (12).jpg',
      title: 'Omar Ghamrawi',
      rows:6,
      cols: 1,
    },
    {
      img: './PortfolioGallery/ph (38).jpg',
      title: 'Girl',
      rows:6,
      cols: 1,
    },
     {
      img: './PortfolioGallery/ph (13).jpg',
      title: 'Omar Ghamrawi',
      rows: 4,
      cols: 2,
    },
    {
      img: './PortfolioGallery/ph (16).jpg',
      title: 'Omar Ghamrawi',
      rows: 4,
      cols: 2,
    },
    {
      img: './PortfolioGallery/ph (48).jpg',
      title: 'Omar Ghamrawi',
      rows:6,
      cols: 1,
    },  
      {
      img: './PortfolioGallery/ph (55).jpg',
      title: 'Omar Ghamrawi',
      rows:6,
      cols: 1,
    },
    {
      img: './PortfolioGallery/ph (18).jpg',
      title: 'Omar Ghamrawi',
      rows:6,
      cols: 1,
    },
    {
      img: './PortfolioGallery/ph (19).jpg',
      title: 'Omar Ghamrawi',
      rows:2,
      cols: 1,
    },
    {
      img: './PortfolioGallery/ph (20).jpg',
      title: 'Omar Ghamrawi',
      rows:2,
      cols: 1,
    },
    {
      img: './PortfolioGallery/ph (21).jpg',
      title: 'Omar Ghamrawi',
      rows:2,
      cols: 1,
    },
    {
      img: './PortfolioGallery/ph (22).jpg',
      title: 'Omar Ghamrawi',
      rows:2,
      cols: 1,
    },
    {
      img: './PortfolioGallery/ph (23).jpg',
      title: 'Omar Ghamrawi',
      rows:2,
      cols: 1,
    },
    {
      img: './PortfolioGallery/ph (24).jpg',
      title: 'Omar Ghamrawi',
      rows:2,
      cols: 1,
    },
  ];

  export default itemData;